.hr
{
	background-color: #eeeeee;
	height: 1px;
	margin: 0 0 20px 0;
	border: 0;
}

@media(max-width: 640px)
{
	.page-profile .row .col-6
	{
		width: 100%;
	}
}
