
/* col-menu */

.fa .col-menu
{
	left: unset;
	right: 0;
}

.fa .col-menu .menu li a, .fa .col-menu .menu li a:visited
{
	text-align: right;
	flex-direction: row-reverse;
}
.fa .col-menu .menu li a:before
{
	background-position: top right;
	left: unset;
	right: -20px;
	transform: scale(-1);
}
.fa .col-menu .menu li a .icon-arrow
{
	transform: scale(-1);
}
