.dialog-alert
{
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
}
.fa .dialog-alert
{
	direction: rtl;
}
.dialog-alert .dialog-alert-content
{
	background-color: #ffffff;
	width: 100%;
	max-width: 480px;
	text-align: center;
	padding: 24px;
	border-radius: 16px;
	position: relative;
}
.dialog-alert .dialog-alert-content .dialog-alert-close
{
	color: rgba(1, 2, 29, 0.3);
	position: absolute;
	top: 24px;
	right: 24px;
	cursor: pointer;
}
.dialog-alert .dialog-alert-content .dialog-alert-image
{

}
.dialog-alert .dialog-alert-content .dialog-alert-image img
{
	width: 120px;
}
.dialog-alert .dialog-alert-content .dialog-alert-title
{
	color: #01021D;
	font-weight: 700;
	font-size: 20px;
	margin-top: 24px;
}
.dialog-alert.error .dialog-alert-content .dialog-alert-title
{
	color: #CF2222;
}
.dialog-alert .dialog-alert-content .dialog-alert-des
{
	color: #01021D;
	font-weight: 300;
	font-size: 16px;
	line-height: 30px;
	margin-top: 24px;
}
