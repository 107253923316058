@font-face
{
	font-family: 'IranSansX';
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/iransans-x/otf/iransans-x-regular.otf") format("woff"),
	url("./fonts/iransans-x/ttf/iransans-x-regular.ttf") format("truetype");
}
@font-face
{
	font-family: 'IranSansX';
	font-style: normal;
	font-weight: 700;
	src: url("./fonts/iransans-x/otf/iransans-x-bold.otf") format("woff"),
	url("./fonts/iransans-x/ttf/iransans-x-bold.ttf") format("truetype");
}

/**********************************************************************************************************************/

body.fa
{
	font-family: "IranSansX", sans-serif !important;
	font-size: 14px;
	direction: rtl;
}

/*.fa .main-body*/
/*{*/
/*	font-family: "IranSansX", sans-serif !important;*/
/*	font-size: 14px;*/
/*	direction: rtl;*/
/*}*/

/*.scrollbar*/
/*{*/
/*	direction: rtl;*/
/*}*/

.fa .dialog-title .title
{
	font-family: "IranSansX", sans-serif !important;
}

/**********************************************************************************************************************/

/* col content */

.fa  .col-content
{
	right: unset;
	left: 0;
}

/* *** */

.fa .col-content .top-bar .breadcrumb li
{
	margin-right: unset;
	margin-left: 18px;
}
.fa .col-content .top-bar .breadcrumb li:last-child
{
	margin-left: 0;
}
.fa .col-content .top-bar .breadcrumb li:before
{
	right: unset;
	left: -12px;
}
.fa .col-content .top-bar .breadcrumb .icon
{
	margin-right: unset;
	margin-left: 8px;
}

/*.col-content .top-bar .btn-menu-drawer*/
/*{*/
/*	margin: 0 24px 0 0 !important;*/
/*}*/


.fa .col-content .top-bar .icon-box
{
	margin-left: unset;
	margin-right: 14px;
}

/* *** */

.fa .col-content .page-content .video-box .btn-play
{
	right: unset;
	left: 24px;
}

.fa .col-content .page-content .page-description
{
	direction: rtl;
}

/**********************************************************************************************************************/

.fa button
{
	font-family: "IranSansX", sans-serif !important;
}

.fa .form-input-box .form-input, .fa .form-input-box .form-select, .fa .form-input-box .form-textarea,
.fa .form-input, .fa .form-select, .fa .form-textarea
{
	font-family: "IranSansX", sans-serif !important;
	text-align: right;
	padding: 14px 14px 14px 38px;
}
.fa .form-input-box .icon
{
	right: unset;
	left: 14px;
}

.fa .form-select-mui-item
{
	direction: rtl;
}
.fa .form-select-mui-item img, .fa .form-select-mui img
{
	margin-right: unset;
	margin-left: 8px;
}

.fa .form-checkbox .icon
{
	margin-right: unset;
	margin-left: 10px;
}

.fa .form-input-box.display-flex
{
	direction: rtl;
}

/**********************************************************************************************************************/

.fa .table-data-t2
{
	direction: rtl;
}

.fa .table-data-t2 tbody td:before
{
	width: 100%;
	height: calc(100% - 32px);
	border-right: unset;
	border-left: 1.5px dashed #e5e5e5;
	content: '';
	position: absolute;
	top: 16px;
	right: 0;
}

/*.fa .table-data-t2 thead th:first-child*/
/*{*/
/*	border-top-right-radius: 12px;*/
/*	border-top-left-radius: unset;*/
/*}*/
/*.fa .table-data-t2 thead th:last-child*/
/*{*/
/*	border-top-left-radius: 12px;*/
/*	border-top-right-radius: unset;*/
/*}*/

.fa .table-data-t2 tbody tr:last-child td:first-child
{
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: unset;
}
.fa .table-data-t2 tbody tr:last-child td:last-child
{
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: unset;
}

/**********************************************************************************************************************/

.fa .row
{
	flex-direction: row-reverse;
}

.fa .btn-custom .m-r-8
{
	margin-right: 0 !important;
	margin-left: 8px;
}

/**********************************************************************************************************************/
