.page-warm-up img
{
	max-width: 100%;
	height: auto;
}
.page-warm-up video
{
	max-width: 100%;
	height: auto;
}
