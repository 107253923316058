/*.fa .alert-data-empty*/
/*{*/
/*	font-family: "IranSansX", sans-serif !important;*/
/*	font-size: 14px;*/
/*	direction: rtl;*/
/*}*/
.page-alert
{
	width: 100%;
	text-align: center;
}
.fa .page-alert
{
	font-family: "IranSansX", sans-serif !important;
	direction: rtl;
}
.page-alert .image img
{
	width: 120px;
}
.page-alert .des
{
	line-height: 30px;
	color: #d7a000;
	font-weight: 700;
	font-size: 20px;
	padding: 24px;
	margin-top: 24px;
}
