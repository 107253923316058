.page-programs .program-items
{
	margin: 4px 0 1px 0;
	overflow: hidden;
}
.page-programs .program-items .item
{
	background-color: #ffffff;
	width: 100%;
	/*height: 450px;*/
	height: 350px;
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	margin-bottom: 28px;
	border: 1px solid #eeeeee;
	border-radius: 16px;
	box-sizing: border-box;
	cursor: pointer;
	transition: background-color 500ms ease-in-out;
}
.page-programs .program-items .item .image-title-des img
{
	width: 100%;
	/*height: 150px;*/
	height: 120px;
	object-fit: contain;
	-o-object-fit: contain;
}
.page-programs .program-items .item .image-title-des .title
{
	color: #01021d;
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	/*margin-top: 14px;*/
	margin-top: 10px;
	transition: color 500ms ease-in-out;
}
.page-programs .program-items .item .image-title-des .des
{
	font-weight: 300;
	font-size: 14px;
	text-align: center;
	/*margin-top: 16px;*/
	margin-top: 10px;
	transition: color 500ms ease-in-out;
}
.page-programs .program-items .item .price-box
{
	background-color: #ffffff;
	width: 100%;
	height: 52px;
	color: #CF2222;
	font-size: 16px;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 15px;
	/*margin-top: 52px;*/
	margin-top: 30px;
	border: 1px solid #CF2222;
	border-radius: 8px;
	box-sizing: border-box;
	transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
}
/*.page-programs .program-items .item .btn-custom*/
/*{*/
/*	font-weight: 600;*/
/*	font-size: 16px;*/
/*	justify-content: space-between;*/
/*	padding: 16px 15px;*/
/*	margin-top: 52px;*/
/*}*/


.page-programs .program-items .item:hover
{
	background-color: #01021D;
}
.page-programs .program-items .item:hover .image-title-des .title
{
	color: #ffffff;
}
.page-programs .program-items .item:hover .image-title-des .des
{
	color: #ffffff;
}
.page-programs .program-items .item:hover .price-box
{
	background-color: #CF2222;
	color: #ffffff;
}

.fa .page-programs .program-items .item .price-box
{
	flex-direction: row-reverse;
}
.fa .page-programs .program-items .item .price-box .icon
{
	transform: scale(-1);
}

.page-programs .program-items .item.new
{
	justify-content: center;
	border: 1px dashed #cf2222;
	cursor: pointer;
}
.page-programs .program-items .item.new:hover
{
	background-color: rgba(207, 34, 34, 0.05);
}
.page-programs .program-items .item.new .title
{
	color: #CF2222;
	font-weight: 700;
	font-size: 18px;
	text-align: center;
}
.page-programs .program-items .item.new .icon
{
	width: 48px;
	height: 48px;
	color: #CF2222;
	margin-top: 18px;
}

@media(max-width: 1024px)
{
	.page-programs .program-items .item
	{
		padding: 10px;
	}
}
@media(max-width: 768px)
{
	.page-programs .program-items .col-4
	{
		width: 50%;
	}
}
@media(max-width: 480px)
{
	.page-programs .program-items .col-4
	{
		width: 100%;
	}
}
