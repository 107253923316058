.page-register
{
	width: 100%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.page-register .col-left
{
	background: #f5f5f5;
	width: 50%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 36px;
	box-sizing: border-box;
}
.page-register .col-left img
{
	width: 100%;
	max-width: 610px;
}

.page-register .section-title
{
	margin-bottom: 32px;
}

.page-register .col-right
{
	width: 50%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	padding: 100px 108px 50px 108px;
	box-sizing: border-box;
}

.page-register .col-right .section-top
{
	width: 100%;
	/*display: inline-flex;*/
	/*align-items: center;*/
	/*justify-content: start;*/
	/*flex-direction: column;*/
}

.page-register .btn-terms-condition
{
	margin-left: 5px;
}

.page-register .col-right .btn-box
{
	width: 100%;
}
.page-register .col-right .btn-custom
{
	margin-top: 24px;
}

.page-register .form-register-verification-code
{
	text-transform: uppercase;
}

.page-register .form-select-box
{
	position: unset;
}
.page-register .form-select
{
	padding-right: 0 !important;
}
.page-register .form-select .MuiInputBase-root
{
	padding: 0 !important;
	min-width: 180px !important;
}
.page-register .form-select .MuiInputBase-input
{
	padding: 0 !important;
}
.page-register .form-select .MuiInputBase-input
{
	padding: 0 !important;
}
.page-register .form-select fieldset
{
	border: 0 !important;
}
.page-register .form-select .icon
{
	position: unset;
	top: unset;
	right: unset;
}

.fa .page-register .form-select
{
	padding: 14px 14px 14px 0 !important;
}
.fa .page-register .form-select .MuiAutocomplete-endAdornment
{
	right: unset;
	left: 9px;
}
.fa .page-register .form-select .MuiInputBase-input::placeholder
{
	font-family: "IranSansX", sans-serif !important;
}

@media(max-width: 1024px)
{
	.page-register .col-right
	{
		padding: 40px 40px 20px 40px;
	}
}
@media(max-width: 768px)
{
	.page-register .col-left
	{
		background: #ffffff;
		width: 100%;
		padding: 20px;
	}

	.page-register .col-right
	{
		background: rgba(255, 255, 255, 0.95);
		width: 100%;
		padding: 40px 120px 20px 120px;
		position: absolute;
		top: 0;
		left: 0;
	}

	.page-register .col-right.step-1 [class*="col-"]
	{
		width: 100%;
	}
}
@media(max-width: 640px)
{
	.page-register .col-right
	{
		padding: 40px 40px 20px 40px;
	}
	.page-register .col-right.step-2 .row
	{
		margin: 0 -5px;
	}
	.page-register .col-right.step-2 [class*="col-"]
	{
		padding: 0 5px;
	}
}
