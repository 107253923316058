
/* col-menu */

.col-menu
{
	background-color: #ffffff;
	width: 258px;
	height: 100%;
	padding: 20px 0 0 0;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
}

.col-menu .user-box
{
	width: 100%;
	text-align: center;
	display: inline-flex;
	align-items: center;
	flex-direction: column;
	padding: 0 20px;
	margin-bottom: 22px;
	box-sizing: border-box;
}
.col-menu .user-box .name-code-box
{
	background-color: #01021D;
	width: 100%;
	height: 138px;
	padding: 20px;
	border-radius: 8px;
	box-sizing: border-box;
}
.col-menu .user-box .user-name
{
	color: #FFFFFF;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}
.col-menu .user-box .user-code
{
	color: #FFFFFF;
	font-size: 14px;
	font-weight: 200;
}
.col-menu .user-box .image
{
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0.60%, rgba(255, 255, 255, 0) 100%);
	width: 90px;
	height: 90px;
	padding: 4px;
	margin-top: -45px;
	border-radius: 100%;
	box-sizing: border-box;
	filter: drop-shadow(0px 34px 50px rgba(0, 0, 0, 0.07));
}

.col-menu .menu-scrollbar
{
	height: calc(100% - 220px) !important;
	float: left;
	margin: 0;
	direction: ltr;
}

.col-menu .menu
{
	width: 100%;
	float: left;
	padding: 0 20px;
	margin: 0;
	box-sizing: border-box;
	direction: ltr;
}
.col-menu .menu li
{
	width: 100%;
	float: left;
	padding-bottom: 12px;
	margin-bottom: 12px;
	list-style: none;
	border-bottom: 1px solid #F3F3F3;
}
.col-menu .menu li:last-child
{
	border-bottom: 0;
}
.col-menu .menu li a, .col-menu .menu li a:visited
{
	width: 100%;
	color: #01021D;
	font-size: 16px;
	font-weight: 400;
	text-align: left;
	padding: 10px 0;
	/*border-left: 3px solid transparent;*/
	transition: color 500ms ease-in-out;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	/*justify-content: center;*/
	position: relative;
}
.col-menu .menu li a .icon
{
	width: 24px;
	height: 24px;
}
.col-menu .menu li a .icon-arrow
{
	width: 14px;
	height: 14px;
	color: #01021D;
	transition: color 500ms ease-in-out;
}
.col-menu .menu li a:hover, .col-menu .menu li.active a,
.col-menu .menu li a:hover .icon-arrow, .col-menu .menu li.active a .icon-arrow
{
	color: #CF2222;
}
.col-menu .menu li a:before
{
	background-image: url("../../images/icon-menu.svg");
	background-repeat: no-repeat;
	background-position: top left;
	background-size: 10px 42px;
	width: 10px;
	height: 42px;
	content: '';
	position: absolute;
	top: 0;
	left: -20px;
	opacity: 0;
	transition: opacity 500ms ease-in-out;
}
.col-menu .menu li a:hover:before, .col-menu .menu li.active a:before
{
	opacity: 1;
}
.col-menu .menu li a .title
{
	flex: 1;
	padding: 0 8px;
}

.menu-drawer .col-menu
{
	position: relative;
	/*padding-right: 16px;*/
}

@media(max-width: 900px)
{
	.col-menu
	{
		display: none;
	}
	.menu-drawer .col-menu
	{
		display: block;
	}
}
