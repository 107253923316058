@font-face
{
	font-family: 'MetronicPro';
	font-weight: 200;
	font-style: normal;
	src: url("./fonts/MetronicPro-Thin.eot");
	src: url("./fonts/MetronicPro-Thin.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro-Thin.woff2") format("woff2"),
	url("./fonts/MetronicPro-Thin.woff") format("woff"),
	url("./fonts/MetronicPro-Thin.ttf") format("truetype");
}
@font-face
{
	font-family: 'MetronicPro';
	font-weight: 300;
	font-style: normal;
	src: url("./fonts/MetronicPro-Light.eot");
	src: url("./fonts/MetronicPro-Light.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro-Light.woff2") format("woff2"),
	url("./fonts/MetronicPro-Light.woff") format("woff"),
	url("./fonts/MetronicPro-Light.ttf") format("truetype");
}
@font-face
{
	font-family: 'MetronicPro';
	font-weight: 400;
	font-style: normal;
	src: url("./fonts/MetronicPro.eot");
	src: url("./fonts/MetronicPro.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro.woff2") format("woff2"),
	url("./fonts/MetronicPro.woff") format("woff"),
	url("./fonts/MetronicPro.ttf") format("truetype");
}
@font-face
{
	font-family: 'MetronicPro';
	font-weight: 600;
	font-style: normal;
	src: url("./fonts/MetronicPro-SemiBold.eot");
	src: url("./fonts/MetronicPro-SemiBold.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro-SemiBold.woff2") format("woff2"),
	url("./fonts/MetronicPro-SemiBold.woff") format("woff"),
	url("./fonts/MetronicPro-SemiBold.ttf") format("truetype");
}
@font-face
{
	font-family: 'MetronicPro';
	font-weight: 700;
	font-style: normal;
	src: url("./fonts/MetronicPro-Bold.eot");
	src: url("./fonts/MetronicPro-Bold.eot?#iefix") format("embedded-opentype"),
	url("./fonts/MetronicPro-Bold.woff2") format("woff2"),
	url("./fonts/MetronicPro-Bold.woff") format("woff"),
	url("./fonts/MetronicPro-Bold.ttf") format("truetype");
}

/**********************************************************************************************************************/

body
{
	background-color: #ffffff;
	min-width: 320px;
	color: #01021D;
	font-family: "MetronicPro", sans-serif !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5em;
	padding: 0;
	margin: 0;
	cursor: default;
	direction: ltr;
	overflow: hidden;
}
body, html, #root, .main-body
{
	background-color: #ffffff;
	width: 100%;
	min-width: 320px;
	height: 100%;
	position: relative;
}
/*
.page-login, .page-forgot-password, .page-register
{
	background-color: #F5F6F7;
}
*/
.scrollbar
{
	direction: ltr;
}

*:focus
{
	box-shadow: none !important;
}

a, a:visited
{
	text-decoration: none !important;
}

.display-none
{
	display: none !important;
}

.clear-both
{
	clear: both;
}

.m-0
{
	margin: 0 !important;
}
.m-l-16
{
	margin-left: 16px !important;
}
.m-t-0
{
	margin-top: 0 !important;
}
.m-t--16
{
	margin-top: -16px !important;
}
.m-t-8
{
	margin-top: 8px !important;
}
.m-t-16
{
	margin-top: 16px !important;
}
.m-t-32
{
	margin-top: 32px !important;
}
.m-b-16
{
	margin-bottom: 16px !important;
}
.m-b-24
{
	margin-bottom: 24px !important;
}
.m-b-32
{
	margin-bottom: 32px !important;
}
.m-r-l-4
{
	margin: 0 4px !important;
}
.m-r-8
{
	margin-right: 8px !important;
}
.m-r-16
{
	margin-right: 16px !important;
}
.m-r-24
{
	margin-right: 24px !important;
}

.p-b-0
{
	padding-bottom: 0 !important;
}

button, .text-transform-unset
{
	text-transform: unset !important;
}

.text-center
{
	text-align: center !important;
}

.container
{
	margin-right: auto;
	margin-left: auto;
	box-sizing: border-box;
	overflow: hidden;
}
.row
{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -14px;
	box-sizing: border-box;
}
.row [class*='col-']
{
	padding: 0 14px;
	box-sizing: border-box;
}
.row .col-12
{
	width: 100%;
}
.row .col-11
{
	width: 91.66666667%;
}
.row .col-10
{
	width: 83.33333333%;
}
.row .col-9
{
	width: 75%;
}
.row .col-8
{
	width: 66.66666667%;
}
.row .col-7
{
	width: 58.33333333%;
}
.row .col-6
{
	width: 50%;
}
.row .col-5
{
	width: 41.66666667%;
}
.row .col-4
{
	width: 33.33333333%;
}
.row .col-3
{
	width: 25%;
}
.row .col-2
{
	width: 16.66666667%;
}
.row .col-1
{
	width: 8.33333333%;
}

/**********************************************************************************************************************/

/* col content */

.col-content
{
	background-color: #f5f5f5;
	width: calc(100% - 258px);
	height: 100%;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	right: 0;
}

@media(max-width: 1024px)
{
	.col-content
	{
		width: 100%;
	}
}

/* *** */

.col-content .top-bar
{
	background-color: #ffffff;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 30px;
	box-sizing: border-box;
}


.col-content .top-bar .breadcrumb
{
	display: inline-flex;
	align-items: center;
	padding: 0;
	margin: 0;
}
.col-content .top-bar .breadcrumb li
{
	color: #01021D;
	font-size: 14px;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	margin-right: 18px;
	list-style: none;
	position: relative;
}
.col-content .top-bar .breadcrumb li:last-child
{
	margin-right: 0;
}
.col-content .top-bar .breadcrumb li:before
{
	background-color: #e5e5e5;
	width: 6px;
	height: 6px;
	border-radius: 100%;
	content: '';
	position: absolute;
	top: 50%;
	right: -12px;
	transform: translateY(-50%);
}
.col-content .top-bar .breadcrumb li:last-child:before
{
	display: none;
}
.col-content .top-bar .breadcrumb li a
{
	color: rgba(1, 2, 29, 0.5);
	font-size: 14px;
	font-weight: 300;
	display: inline-flex;
	align-items: center;
	transition: color 500ms ease-in-out;
}
.col-content .top-bar .breadcrumb li a:hover
{
	color: #CF2222;
}
.col-content .top-bar .breadcrumb .icon
{
	color: #01021D;
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.col-content .top-bar .btn-menu-drawer
{
	display: none !important;
	margin: 0 24px 0 0 !important;
}

.col-content .top-bar .col-icon
{
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
}
.col-content .top-bar .icon-box
{
	background-color: #ffffff;
	width: 48px;
	height: 48px;
	color: #01021D;
	font-size: 16px;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: 14px;
	border: 1px solid #EEEEEE;
	border-radius: 100%;
	cursor: pointer;
	direction: ltr;
	transition: all 500ms ease-in-out;
}
.col-content .top-bar .icon-box .char-color2
{
	opacity: 0.4;
}
.col-content .top-bar .icon-box .icon
{
	width: 22px;
	height: 22px;
}
.col-content .top-bar .icon-box:hover
{
	border-color: #CF2222;
}

@media(max-width: 1024px)
{
	.col-content .top-bar .btn-menu-drawer
	{
		display: inline-flex !important;
	}
}
@media(max-width: 640px)
{
	.col-content .top-bar
	{
		padding: 10px 10px;
	}

	.col-content .top-bar .breadcrumb li
	{
		font-size: 12px;
		margin-right: 10px;
	}
	.col-content .top-bar .breadcrumb li:last-child
	{
		margin-right: 0;
	}
	.col-content .top-bar .breadcrumb li:before
	{
		width: 3px;
		height: 3px;
		right: -6px;
	}
	.col-content .top-bar .breadcrumb li a
	{
		font-size: 12px;
		font-weight: 400;
	}
	.col-content .top-bar .breadcrumb .icon
	{
		width: 18px;
		height: 18px;
	}

	.col-content .top-bar .icon-box
	{
		width: 32px;
		height: 32px;
		margin-left: 8px;
	}
	.col-content .top-bar .icon-box .icon
	{
		width: 18px;
		height: 18px;
	}

	.col-content .top-bar .btn-menu-drawer
	{
		margin: 0 16px 0 0 !important;
	}
}

/* *** */

.col-content .section-footer
{
	margin-top: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
}
.col-content .section-footer.flex-end
{
	justify-content: flex-end;
}

/* *** */

.col-content .page-content
{
	background-color: #ffffff;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 30px;
	margin: 30px;
	border: 1px solid #F3F3F3;
	border-radius: 4px;
	position: relative;
	/*overflow: hidden;*/
}

.col-content .page-content .page-description
{
	line-height: 30px;
	color: #01021D;
	font-weight: 300;
	font-size: 16px;
	text-align: justify;
}
.col-content .page-content .page-description h1,
.col-content .page-content .page-description h2,
.col-content .page-content .page-description h3,
.col-content .page-content .page-description h4,
.col-content .page-content .page-description h5
{
	line-height: 30px;
	color: #01021D;
	font-weight: 700;
	font-size: 16px;
	text-align: justify;
	padding: 0;
	margin: 0;
}
.col-content .page-content p
{
	margin: 0;
}
.col-content .page-content .image-box
{
	width: 100%;
	text-align: center;
	margin: 42px 0;
}
.col-content .page-content .image-box img
{
	width: 100%;
	max-width: 700px;
}
.col-content .page-content .video-box
{
	width: 100%;
	max-width: 700px;
	text-align: center;
	margin: 42px auto;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}
.col-content .page-content .video-box.no-play:before
{
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
}
.col-content .page-content .video-box .btn-play
{
	background-image: url("../images/icon-play.svg");
	width: 52px;
	height: 52px;
	content: '';
	position: absolute;
	bottom: 24px;
	right: 24px;
	cursor: pointer;
}
.col-content .page-content .video-box video
{
	width: 100%;
	max-width: 700px;
}
.col-content .page-content .video-box .caption
{
	width: 100%;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 400;
	text-align: left;
	padding: 24px;
	position: absolute;
	left: 0;
	bottom: 0;
}

.page-content-404
{
	/*width: 100%;*/
	height: 100%;
	position: relative;
}

@media(max-width: 640px)
{
	.col-content .page-content
	{
		padding: 20px;
		margin: 20px 10px;
	}
}

/**********************************************************************************************************************/

.page-404
{
	width: 100%;
	height: 100%;
	position: relative;
}
.page-404 img
{
	width: 100%;
	max-width: 600px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/**********************************************************************************************************************/

::-webkit-input-placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}
:-moz-placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}
::-moz-placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}
:-ms-input-placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}

.form-input-box
{
	width: 100%;
	position: relative;
}
.form-input-box.display-flex
{
	display: inline-flex;
	align-items: center;
}
.form-input-box .form-label
{
	color: #01021D;
	font-size: 14px;
	font-weight: 700;
	display: block;
	margin-bottom: 5px;
}
.form-input-box .form-label.error
{
	color: #cf2222;
}
.form-input-box .form-input, .form-input-box .form-select, .form-input-box .form-textarea,
.form-input, .form-select, .form-textarea
{
	background-color: #F5F5F5;
	width: 100%;
	height: 52px;
	color: #01021D;
	font-size: 14px;
	font-weight: 400;
	font-family: "MetronicPro", sans-serif !important;
	padding: 14px 38px 14px 14px;
	margin-bottom: 24px;
	border: 1px solid #EEEEEE;
	border-radius: 8px;
	box-shadow: unset;
	outline: unset;
	box-sizing: border-box;
	resize: none;
}
.form-input.error, .form-select.error, .form-textarea.error
{
	background-color: #fff4f4;
	border-color: #cf2222;
}
.form-input-box .form-input.no-icon
{
	padding: 14px;
}
.form-input-box .form-select
{
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}
.form-input-box .form-select.placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}
.form-input-box .form-select option
{
	color: #01021D;
}
.form-input-box .form-input:focus
{
	border-color: #bababa;
}
.form-input-box .icon
{
	color: #ADADAD;
	font-size: 20px;
	position: absolute;
	top: 16px;
	right: 14px;
}

.form-select-mui fieldset
{
	border: 1px solid #EEEEEE !important;
}
.form-select-mui fieldset:focus
{
	border-color: #bababa !important;
}
.form-select-mui .placeholder
{
	color: #ADADAD;
	font-size: 14px;
	font-weight: 400;
}
.form-select-mui-item
{
	color: #01021D;
	font-size: 14px;
	font-weight: 400;
}
.form-select-mui-item img, .form-select-mui img
{
	width: 24px;
	margin-right: 8px;
}
.form-select-mui .MuiSelect-select
{
	display: inline-flex;
	padding: 0;
}
.form-select-mui .MuiSelect-icon
{
	display: none;
}


.form-checkbox
{
	font-weight: 300;
	font-size: 16px;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
}
.form-checkbox .icon
{
	background-color: #ffffff;
	width: 24px;
	height: 24px;
	color: #CF2222;
	margin-right: 10px;
	border: 1px solid #CF2222;
	border-radius: 6px;
}
.form-checkbox.error
{
	color: #CF2222;
}

/* hide caret of input number */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
{
	-webkit-appearance: none;
	margin: 0;
}
input[type=number]
{
	-moz-appearance: textfield;
}

/**********************************************************************************************************************/

.table-data
{
	background-color: #ffffff;
	width: 100%;
	border-spacing: 0;
	position: relative;
	z-index: 0;
}

.table-data thead
{
	background-color: #f8f9f9;
}
.table-data thead.sticky
{
	position: sticky;
	top: 0;
	z-index: 1;
}
.table-data thead th
{
	color: #666666;
	font-size: 12px;
	font-weight: bold;
	text-align: left;
	padding: 12px 12px;
	box-sizing: border-box;
	white-space: nowrap;
}
.table-data thead th.center
{
	text-align: center;
}
.table-data thead th.center .col-value
{
	justify-content: center;
}
.table-data thead .col-value
{
	width: 100%;
	display: flex;
	align-items: center;
}
.table-data thead .col-sort
{
	color: #9e9e9e;
	font-size: 20px;
	transform: rotate(90deg);
}
.table-data thead .col-sort.asc
{
	color: #005ce8;
	transform: rotate(-90deg);
}
.table-data thead .col-sort.desc
{
	color: #005ce8;
	transform: rotate(90deg);
}

.table-data tbody
{

}
.table-data tbody td
{
	color: #666666;
	font-size: 14px;
	text-align: left;
	padding: 12px 12px;
	border-top: 1px solid #e5e7e8;
	white-space: nowrap;
}
.table-data tbody td.center
{
	text-align: center;
}
.table-data tbody td.color-gray-900
{
	color: #666666;
}
.table-data tbody tr:hover td
{
	background-color: #f0f6ff;
	color: #666666;
}

.table-data tbody td .icon-edit
{
	color: #005ce8;
}
.table-data tbody td .icon-delete
{
	color: #e84646;
}

.table-data tbody a, .table-data tbody a:visited
{
	color: #005ce8;
	font-size: 14px;
}
.table-data tbody a:hover
{
	color: #2386ff;
}

.table-data tfoot
{
	background-color: #ffffff;
}
.table-data tfoot.sticky
{
	position: sticky;
	bottom: 0;
}
.table-data tfoot td
{
	padding: 16px 12px;
	border-top: 3px solid #e5e7e8;
}

/* *** */

.table-data-t2
{
	background-color: #ffffff;
	width: 100%;
	border: 1px solid #eeeeee;
	border-radius: 12px;
	border-spacing: 0;
	position: relative;
	z-index: 0;
}

.table-data-t2 thead th
{
	background-color: #01021d;
	color: #ffffff;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	padding: 18px;
	box-sizing: border-box;
}
.table-data-t2 thead th:first-child
{
	border-top-left-radius: 12px;
}
.table-data-t2 thead th:last-child
{
	border-top-right-radius: 12px;
}

.table-data-t2 tbody td
{
	background-color: #f5f5f5;
	line-height: 30px;
	color: #01021d;
	font-size: 14px;
	font-weight: 300;
	text-align: center;
	padding: 18px;
	position: relative;
}
.table-data-t2 tbody tr:nth-child(even) td
{
	background-color: #ffffff;
}
.table-data-t2 tbody td:before
{
	width: 100%;
	height: calc(100% - 32px);
	border-right: 1.5px dashed #e5e5e5;
	content: '';
	position: absolute;
	top: 16px;
	right: 0;
}
.table-data-t2 tbody td:last-child:before
{
	border: 0;
}

.table-data-t2 tbody tr:last-child td:first-child
{
	border-bottom-left-radius: 12px;
}
.table-data-t2 tbody tr:last-child td:last-child
{
	border-bottom-right-radius: 12px;
}

/**********************************************************************************************************************/

.label-status
{
	background-color: #ededed;
	color: #666666;
	display: inline-block;
	padding: 4px 8px;
	border-radius: 4px;
}
.label-status.paid
{
	background-color: #e7f7ef;
	color: #0faf62;
}
.label-status.unpaid, .label-status.waiting-answer
{
	background-color: #fff2d5;
	color: #ad8a3c;
}
.label-status.completed, .label-status.answered
{
	background-color: #f0f6ff;
	color: #005ce8;
}
.label-status.payment-failed
{
	background-color: #fffdea;
	color: #9f8d50;
}
.label-status.cancelled
{
	background-color: #ffeae8;
	color: #d9534f;
}

.label-status.link
{
	cursor: pointer;
}

.label-tag
{
	background-color: #ced7e1;
	color: #53677d;
	font-size: 13px;
	display: inline-block;
	padding: 4px 8px;
	margin-right: 4px;
	border-radius: 4px;
	white-space: nowrap;
}
.label-tag:last-child
{
	margin-right: 0;
}

/**********************************************************************************************************************/

.w-max-300
{
	width: 100%;
	max-width: 300px;
}

.w-50-percent
{
	width: 50%;
}

.w-30
{
	width: 30px;
}
.w-40
{
	width: 40px;
}
.w-60
{
	width: 60px;
}
.w-80
{
	width: 80px;
}
.w-100
{
	width: 100px;
}
.w-120
{
	width: 120px;
}
.w-140
{
	width: 140px;
}
.w-160
{
	width: 160px;
}
.w-180
{
	width: 180px;
}

.h-80
{
	height: 80px !important;
}
.h-100
{
	height: 100px !important;
}

/**********************************************************************************************************************/

.dialog .MuiDialog-paper
{
	padding: 24px;
	box-sizing: border-box;
}
.dialog.full .MuiDialog-paper
{
	width: 100% !important;
	max-width: unset;
	height: 100% !important;
	max-height: unset;
	margin: 0 !important;
}
.dialog-title
{
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0 8px 0 !important;
	margin-bottom: 24px !important;
	border-bottom: 2px solid #e5e7e8;
}
.dialog-title .title
{
	color: #01021D;
	font-weight: 700;
	font-size: 18px;
	font-family: "MetronicPro", sans-serif !important;
}
.dialog-close
{
	width: 24px;
	height: 24px;
	/*float: right;*/
	cursor: pointer;
}

.dialog-content
{
	line-height: 30px;
	color: #01021D;
	font-weight: 300;
	font-size: 16px;
	text-align: justify;
	padding: 0 !important;
}
.dialog-content .scrollbar
{
	width: 100% !important;
	height: 350px !important;
}

.dialog-content-des p
{
	/*font-size: 14px;*/
}
.dialog-content-des .title
{
	font-size: 18px;
	font-weight: 700;
}

.dialog-actions
{
	padding: 16px 24px !important;
	justify-content: flex-start !important;
}

/**********************************************************************************************************************/

.page-section-container
{
	max-width: unset !important;
	padding: 0 !important;
}

.page-section-box
{
	background-color: #ffffff;
	width: 100%;
	padding: 24px;
	border-radius: 8px;
	box-sizing: border-box;
}
/*.page-section-box .section-title*/
/*{*/
/*	!*color: #a4a4a4;*!*/
/*	color: #798593;*/
/*	!*font-weight: 500;*!*/
/*	font-weight: 500;*/
/*	font-size: 16px;*/
/*	padding-bottom: 8px;*/
/*	border-bottom: 1px solid #e8e8e8;*/
/*}*/
.page-section-box .content
{
	margin-top: 24px;
}

.page-section-box .content .btn-section
{
	text-transform: unset;
	margin-bottom: 12px;
}
.page-section-box .content .btn-section:last-child
{
	margin-bottom: 0;
}

.page-section-box .section-des
{
	text-align: justify;
}

/* * */

.section-title
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid #eeeeee;
	text-transform: uppercase;
}
.section-title .title-box
{
}
.section-title .title-box .title
{
	font-size: 22px;
	font-weight: 700;
}
.section-title .title-box .title-t2
{
	font-size: 22px;
	font-weight: 300;
}

@media(max-width: 768px)
{
	.section-title
	{
		padding-bottom: 16px;
		margin-bottom: 16px;
	}
	.section-title .title-box .title
	{
		font-size: 18px;
	}
	.section-title .title-box .title-t2
	{
		font-size: 18px;
	}
}

/* btn link */

.btn-custom
{
	background-color: #CF2222;
	/*width: 100%;*/
	height: 52px;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 45px;
	border: 1px solid #CF2222;
	border-radius: 8px;
	box-sizing: border-box;
	cursor: pointer;
	transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
}
.btn-custom.full
{
	width: 100%;
}
.btn-custom:hover
{
	background-color: #FFFFFF;
	color: #CF2222;
}

.btn-custom.t2
{
	background-color: #FFFFFF;
	color: #CF2222;
}
.btn-custom.t2:hover
{
	background-color: #CF2222;
	color: #FFFFFF;
}

.btn-custom.mini
{
	height: unset;
	font-size: 14px;
	padding: 10px 10px;
}

.link-custom
{
	color: #01021D;
	font-size: 16px;
	font-weight: 400;
	transition: color 500ms ease-in-out;
}
.link-custom.border-bottom
{
	/*padding-bottom: 0px;*/
	border-bottom: 1px solid #01021D;
}
.link-custom:hover
{
	color: #CF2222;
}

@media(max-width: 1024px)
{
	.btn-custom
	{
		padding: 0 20px;
	}
}
@media(max-width: 768px)
{
	.btn-custom
	{
		font-size: 16px;
	}
	.btn-custom.mini
	{
		font-size: 12px;
	}
}

/**********************************************************************************************************************/

/*.table-new-order-data .net-total, .table-new-order-data .package-discount, .table-new-order-data .package-price*/
/*{*/
/*	color: #0041a2;*/
/*	font-size: 14px;*/
/*	font-weight: bold;*/
/*}*/
/*.table-new-order-data .package-discount*/
/*{*/
/*	color: #0faf62;*/
/*}*/
/*.table-new-order-data .package-price*/
/*{*/
/*	color: #0041a2;*/
/*}*/
/*.table-new-order-data .net-total .td-title,*/
/*.table-new-order-data .package-discount .td-title,*/
/*.table-new-order-data .package-price .td-title*/
/*{*/
/*	text-align: right;*/
/*}*/
/*.table-new-order-data .net-total .td-title .des*/
/*{*/
/*	font-size: 12px;*/
/*	font-weight: normal;*/
/*}*/
/*.table-new-order-data input::-webkit-outer-spin-button, .table-new-order-data input::-webkit-inner-spin-button*/
/*{*/
/*	-webkit-appearance: none;*/
/*	margin: 0;*/
/*}*/
/*.table-new-order-data input[type=number]*/
/*{*/
/*	-moz-appearance: textfield;*/
/*}*/
/*.table-new-order-data .th-qty*/
/*{*/
/*	min-width: 100px;*/
/*}*/
/*.table-new-order-data .td-qty*/
/*{*/
/*	position: relative;*/
/*}*/
/*.table-new-order-data .td-qty .form-input-number*/
/*{*/
/*	margin-bottom: 0;*/
/*}*/
/*.table-new-order-data .btn-increase-decrease-box*/
/*{*/
/*	width: 40px;*/
/*	display: inline-flex;*/
/*	flex-direction: column;*/
/*	position: absolute;*/
/*	top: 12px;*/
/*	right: 12px;*/
/*}*/
/*.table-new-order-data .btn-increase-decrease-box button*/
/*{*/
/*	width: 40px;*/
/*	min-width: unset;*/
/*	height: 20px;*/
/*	border-radius: 0;*/
/*}*/

/*.table-new-order-link-data .form-input*/
/*{*/
/*	min-width: 160px;*/
/*	margin-bottom: 0;*/
/*}*/
/*.table-new-order-link-data th*/
/*{*/
/*	padding: 8px 4px 8px 0 !important;*/
/*}*/
/*.table-new-order-link-data td*/
/*{*/
/*	padding: 4px 4px 4px 0 !important;*/
/*}*/
/*.table-new-order-link-data td:first-child*/
/*{*/
/*	min-width: 40px;*/
/*	text-align: center;*/
/*}*/

/*.table-new-order-data tfoot td*/
/*{*/
/*	padding-bottom: 0;*/
/*}*/
/*.table-new-order-data tfoot tr:not(:first-child) td*/
/*{*/
/*	border: 0;*/
/*}*/
/*.table-new-order-data tfoot tr:last-child td*/
/*{*/
/*	padding-bottom: 16px;*/
/*	border-bottom: 3px solid #e5e7e8;*/
/*}*/

/*.row-btn-build-package-checkbox-csv*/
/*{*/
/*	display: inline-flex;*/
/*	flex-direction: column;*/
/*	align-items: flex-start;*/
/*	margin-top: 32px;*/
/*}*/
/*.row-btn-build-package-checkbox-csv .checkbox-box*/
/*{*/
/*	font-size: 14px;*/
/*	margin: 0;*/
/*}*/
/*.row-btn-build-package-checkbox-csv .checkbox-box .MuiCheckbox-root*/
/*{*/
/*	padding: 0;*/
/*}*/
/*.row-btn-build-package-checkbox-csv .checkbox-box .csv-des*/
/*{*/
/*	color: #666666;*/
/*	font-size: 12px;*/
/*}*/
/*.row-btn-build-package-checkbox-csv button*/
/*{*/
/*	margin-top: 16px;*/
/*}*/

/*.new-order-input-csv-upload*/
/*{*/
/*	display: none !important;*/
/*}*/

/**********************************************************************************************************************/

.grecaptcha-badge
{
	display: none;
}

/**********************************************************************************************************************/
