.page-new-program .program-items
{
	margin: 4px 0 1px 0;
	overflow: hidden;
}
.page-new-program .program-items .item
{
	background-color: #ffffff;
	width: 100%;
	/*height: 410px;*/
	height: 350px;
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	margin-bottom: 28px;
	border: 1px solid #eeeeee;
	border-radius: 16px;
	box-sizing: border-box;
	cursor: pointer;
	transition: background-color 500ms ease-in-out;
}
.page-new-program .program-items .item .image-title-des img
{
	width: 100%;
	/*height: 150px;*/
	height: 120px;
	object-fit: contain;
	-o-object-fit: contain;
}
.page-new-program .program-items .item .image-title-des .title
{
	color: #01021d;
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	/*margin-top: 14px;*/
	margin-top: 10px;
	transition: color 500ms ease-in-out;
}
.page-new-program .program-items .item .image-title-des .des
{
	font-weight: 300;
	font-size: 14px;
	text-align: center;
	/*margin-top: 16px;*/
	margin-top: 10px;
	transition: color 500ms ease-in-out;
}
.page-new-program .program-items .item .price-box
{
	background-color: #ffffff;
	width: 100%;
	height: 52px;
	color: #CF2222;
	font-size: 16px;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 15px;
	/*margin-top: 52px;*/
	margin-top: 30px;
	border: 1px solid #CF2222;
	border-radius: 8px;
	box-sizing: border-box;
	transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
}


.page-new-program .program-items .item:hover
{
	background-color: #01021D;
}
.page-new-program .program-items .item:hover .image-title-des .title
{
	color: #ffffff;
}
.page-new-program .program-items .item:hover .image-title-des .des
{
	color: #ffffff;
}
.page-new-program .program-items .item:hover .price-box
{
	background-color: #CF2222;
	color: #ffffff;
}





.fa .page-new-program .program-items .item .price-box
{
	/*flex-direction: row-reverse;*/
}
.fa .page-new-program .program-items .item .price-box .icon
{
	transform: scale(-1);
}























.page-new-program .page-description
{
	margin-bottom: 32px;
}

.page-new-program .step-box
{
	width: 100%;
}
.page-new-program .step-box.by-program-item
{
	/*height: 100%;*/
	display: inline-flex;
	flex-direction: column;
	overflow: hidden;
}
/*.page-new-program .step-box .row.row-program-item*/
/*{*/
/*	height: 100%;*/
/*	padding-bottom: 2px;*/
/*}*/


.page-new-program .program-item
{
	background-color: #ffffff;
	width: 100%;
	height: 100%;
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 24px;
	border: 1px solid #eeeeee;
	border-radius: 16px;
	box-sizing: border-box;
}
.page-new-program .program-item .image-title
{
	flex: 1;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
}
.page-new-program .program-item .image-title img
{
	width: 100%;
	max-height: 250px;
	object-fit: contain;
	-o-object-fit: contain;
}
.page-new-program .program-item .image-title .title
{
	color: #01021d;
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	margin-top: 14px;
}
.page-new-program .program-item .btn-custom
{
	font-weight: 600;
	font-size: 18px;
	padding: 16px;
	margin-top: 14px;
}




.page-new-program .form-label
{
	width: 150px;
}




.page-new-program .btn-image-file
{
	background-color: #ffffff;
	width: 100%;
	color: #01021D;
	font-weight: 600;
	font-size: 16px;
	text-transform: capitalize;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	margin-bottom: 28px;
	border: 1px solid #eeeeee;
	border-radius: 10px;
	box-sizing: border-box;
	cursor: pointer;
}
.page-new-program .btn-image-file img
{
	width: 60px;
	height: 60px;
	object-fit: cover;
	-o-object-fit: cover;
	margin-right: 16px;
}
.page-new-program .btn-image-file .icon-box
{
	background-color: #ffffff;
	width: 42px;
	height: 42px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #cf2222;
	border-radius: 8px;
}
.page-new-program .btn-image-file .icon-box .icon
{
	color: #cf2222;
	font-size: 20px;
	position: unset;
	top: unset;
	right: unset;
}
.page-new-program .btn-image-file input
{
	display: none;
}

.page-new-program .btn-image-file.receipt
{
	background-color: #fafafa;
	max-width: 300px;
	padding: 50px 20px;
	margin-bottom: 50px;
}
.page-new-program .btn-image-file.receipt.selected
{
	color: #109300;
}
.page-new-program .btn-image-file.receipt .icon
{
	font-size: 20px;
	margin-right: 10px;
	position: unset;
	top: unset;
	right: unset;
}
.page-new-program .btn-image-file.error
{
	color: #cf2222;
	border-color: #cf2222;
}

.page-new-program .price-box
{
	background-color: #01021d;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	color: #ffffff;
	font-weight: 300;
	font-size: 16px;
	padding: 20px 0;
	border-radius: 10px;
}
.page-new-program .price-box .item
{
	width: 33.33%;
	display: inline-flex;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
}
.page-new-program .price-box .item:nth-child(2)
{
	border: 1.5px dashed rgba(255, 255, 255, 0.7);
	border-top: 0;
	border-bottom: 0;
}
.page-new-program .price-box .item .icon
{
	width: 28px;
	height: 28px;
	margin-right: 8px;
}
.page-new-program .price-box .item .title-price
{
	flex: 1;
	display: inline-flex;
	justify-content: space-between;
}
.page-new-program .price-box .item .title
{
}
.page-new-program .price-box .item .price
{
	font-weight: 700;
}

.fa .page-new-program .price-box
{
	/*flex-direction: row-reverse;*/

	direction: rtl;
}
.fa .page-new-program .price-box .item .icon
{
	margin-right: 0;
	margin-left: 8px;
}

@media(max-width: 1024px)
{
	.page-new-program .program-items .item
	{
		padding: 10px;
	}
}
@media(max-width: 768px)
{
	.page-new-program .program-items .col-4
	{
		width: 50%;
	}

	.page-new-program .price-box
	{
		flex-direction: column;
	}
	.page-new-program .price-box .item
	{
		width: 100%;
	}
	.page-new-program .price-box .item:nth-child(2)
	{
		padding: 10px 20px;
		margin: 10px 0;
		border: 1.5px dashed rgba(255, 255, 255, 0.7);
		border-left: 0;
		border-right: 0;
	}
}
@media(max-width: 640px)
{
	.page-new-program .row .col-6
	{
		width: 100%;
	}
}
@media(max-width: 480px)
{
	.page-new-program .program-items .col-4
	{
		width: 100%;
	}
}
