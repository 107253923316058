.page-forget-password
{
	width: 100%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.page-forget-password .col-left
{
	background: #f5f5f5;
	width: 50%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 36px;
	box-sizing: border-box;
}
.page-forget-password .col-left img
{
	width: 100%;
	max-width: 610px;
}

.page-forget-password .section-title
{
	margin-bottom: 32px;
}

.page-forget-password .col-right
{
	width: 50%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	padding: 100px 108px 50px 108px;
	box-sizing: border-box;
}

.page-forget-password .col-right .section-top
{
	width: 100%;
	/*display: inline-flex;*/
	/*align-items: center;*/
	/*justify-content: start;*/
	/*flex-direction: column;*/
}

.page-forget-password .btn-terms-condition
{
	margin-left: 5px;
}

.page-forget-password .col-right .btn-box
{
	width: 100%;
}
.page-forget-password .col-right .btn-custom
{
	margin-top: 24px;
}

.page-forget-password .form-forget-password-verification-code
{
	text-transform: uppercase;
}

@media(max-width: 1024px)
{
	.page-forget-password .col-right
	{
		padding: 40px 40px 20px 40px;
	}
}
@media(max-width: 768px)
{
	.page-forget-password .col-left
	{
		background: #ffffff;
		width: 100%;
		padding: 20px;
	}

	.page-forget-password .col-right
	{
		background: rgba(255, 255, 255, 0.95);
		width: 100%;
		padding: 40px 120px 20px 120px;
		position: absolute;
		top: 0;
		left: 0;
	}
}
@media(max-width: 640px)
{
	.page-forget-password .col-right
	{
		padding: 40px 40px 20px 40px;
	}
	.page-forget-password .col-right.step-2 .row
	{
		margin: 0 -5px;
	}
	.page-forget-password .col-right.step-2 [class*="col-"]
	{
		padding: 0 5px;
	}
}
